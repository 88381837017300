import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  name: string = "";
  email: string = "";
  message: string = "";

  success: boolean;
  error: boolean;

  constructor(
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
  }

  async goEmail() {
    var data = { "name": this.name, "message": this.message, "email": this.email };

    const body = new HttpParams()
      .set('name', this.name)
      .set('message', this.message)
      .set('email', this.email);

    var response = await this.homeService.sendEmail(body);
    this.success = false;
    this.error = false;
    console.log(response);
    if (response["status"] == "200") {
      this.name = "";
      this.message = "";
      this.email = "";
      this.success = true;
      this.error = false;
    }else{
      this.error= true;
      this.success = false;
    }
    //alert(response["message"]);
  }
}
