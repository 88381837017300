<div class="page">
    <!-- Page Header-->
    <header class="page-head bg-ecstasy bg-image bg-image-1 image-wrap image-wrap-variant-1 bottom-compensation">
        <!--<section class="section-top-60 section-bottom-100 section-sm-bottom-60">
            <div class="shell">
                <div class="range range-sm-middle text-center text-md-left">
                    <div class="cell-xs-12 cell-md-8">
                        <a href="./" class="brand">
                            <div class="brand-logo">
                                <img src="assets/images/logo_sindsa.png" alt="" width="83" height="83">
                            </div>
                            <span class="brand-slogan h5 text-primary">Seguridad E Higiene</span>
                        </a>
                    </div>
                    <div class="cell-xs-12 cell-md-4 text-md-right offset-sm-top-20 offset-md-top-0">
                        <div class="item-inline">
                            <div class="item-inline-left">
                                <i class="fas fa-phone"></i> &nbsp;
                            </div>
                            <div class="item-inline-body">
                                <a href="callto:#" class="h6 link-inverse">818 704 6984</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->

        <section class="section-sm-top-60 section-sm-bottom-100 section-md-top-130 section-lg-bottom-100 text-center">
            <div class="image-wrap-body">
                <div class="displacement-1"><img src="assets/images/logo_sindsa.png" alt="" width="781" height="674"
                        style="height: 226px;"></div>
            </div>
            <h2>Suministros Industriales S.A.</h2>
        </section>

        <div class="absolute-wrap absolute-wrap-variant-1">
            <a href="#main" class="banner scroll-down-button">
                <img src="assets/images/banner.webp" alt="" width="480" height="269">
                <div class="banner-body">
                    <h4>
                        <span class="text-safety-orange">Nuestos</span>Productos
                    </h4>
                    <i class="fas fa-arrow-circle-down icon-sm icon-safety-orange fl-bigmug-line-down58"></i>
                </div>
            </a>
        </div>

    </header>
    <!-- Page Content -->
    <main class="page-content">
        <!-- Welcome -->
        <!--<section class="section-80 section-sm-130 section-md-bottom-163 text-center">
            <div class="shell">
                <div class="range range-xs-center">
                    <div class="cell-xs-12 cell-sm-11 cell-md-8">
                        <h2><span class="h5 text-gray-lighter">Bienvenido a </span>Suministro Industriales S.A.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    </div>
                </div>
            </div>
        </section>-->

        <!-- what we offer-->
        <section
            class="empresary section-top-100 section-bottom-130 section-sm-181 section-lg-bottom-204 section-decorated bg-image bg-image-2 text-center"
            style="background-image: url('assets/images/bg-image-2.webp')">
            <div class="decoration-element decoration-element-top"></div>
            <div class="decoration-element decoration-element-bottom"></div>
            <div class="shell">
                <div class="range offset-top-41 offset-sm-top-80 offset-lg-top-133">
                    <div class="cell-xs-12 cell-sm-6 cell-md-4">
                        <div class="image-icon-wrap">
                            <div class="image-icon-wrap-image">
                                <img src="assets/images/decoration-element-1.webp" alt="" width="172" height="172">
                            </div>
                            <div class="image-icon-wrap-icon">
                                <span class="fas fa-lightbulb icon-lg icon-white fl-sympletts-house181"></span>
                            </div>
                        </div>
                        <h5>Mision</h5>
                        <p>Proporcionar soluciones en suministro industrial, residencial, institucional y comercial que
                            supere las expectativas de nuestros clientes creando sinergia clientes-proveedores.</p>
                    </div>
                    <div class="cell-xs-12 cell-sm-6 cell-md-4 offset-top-51 offset-sm-top-0">
                        <div class="image-icon-wrap">
                            <div class="image-icon-wrap-image">
                                <img src="assets/images/decoration-element-1.webp" alt="" width="172" height="172">
                            </div>
                            <div class="image-icon-wrap-icon">
                                <i class="fas fa-eye icon-lg icon-white fl-sympletts-target58"></i>
                            </div>
                        </div>
                        <h5>Vision</h5>
                        <p>Ser una empresa líder a nivel nacional en suministros industriales, residencial,
                            institucional y comercial, consolidándose para lograr un crecimiento organizado y
                            promoviendo la superación integral de sus integrantes.</p>
                    </div>
                    <div class="cell-xs-12 cell-sm-6 cell-md-4 offset-top-51 offset-md-top-0">
                        <div class="image-icon-wrap">
                            <div class="image-icon-wrap-image">
                                <img src="assets/images/decoration-element-1.webp" alt="" width="172" height="172">
                            </div>
                            <div class="image-icon-wrap-icon">
                                <span class="fas fa-heart icon-lg icon-white fl-sympletts-star206"></span>
                            </div>
                        </div>
                        <h5>Valores</h5>
                        <p>
                            Cuando hacemos las cosas bien desde la primera vez, cumplimos la promesa básica que hacemos
                            diariamente a nuestros clientes: Superar siempre sus expectativas. Cada vez que entregamos a
                            un cliente un producto o le brindamos un servicio, estamos representando el trabajo de
                            nuestros compañeros, quienes con entrega y disciplina dan lo mejor de sí para que éste sea
                            de la mejor calidad.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Offerings-->
        <section class="section-sm-100 section-lg-top-130 section-lg-bottom-163">
            <article class="bottom-compensation-1">
                <div class="shell">
                    <div class="range">
                        <div class="cell-xs-12 cell-sm-8">
                            <div class="section-top-100 section-sm-bottom-80 section-lg-top-163 section-lg-bottom-163">
                                <div class="range">
                                    <div class="cell-xs-12">
                                        <h3>Solo calidad en Materiales Industriales Seguridad</h3>
                                        <h3>y de Limpieza</h3>
                                    </div>
                                    <div class="cell-xs-12 cell-md-9 offset-top-20 offset-lg-top-51">
                                        <p>
                                            SINDSA es una empresa regiomontana que nace en el 2015, dedicados al
                                            suministro de insumos industriales en diferentes ramas, enfocándonos en la
                                            seguridad industrial..
                                        </p>
                                        <p class="mobile-text">
                                            Ofreciendo las mejores marcas en el mercado industrial, servicio
                                            personalizado, programas de stock programado, soporte técnico 24/7 en su
                                            empresa o en nuestras líneas telefónicas.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cell-xs-12 cell-sm-4">
                            <div class="relative">
                                <div class="image-wrap image-wrap-variant-2"><img src="assets/images/home-2.png" alt=""
                                        width="647" height="686"></div>
                                <div class="decoration-block decoration-block-right bg-reef"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article
                class="resposive-text section-sm-bottom-80 section-sm-top-100 section-md-top-181 section-md-bottom-130 offset-top-61 offset-sm-top-0">
                <div class="shell">
                    <div class="range">
                        <div class="cell-xs-12 cell-sm-8 cell-sm-push-1">
                            <div class="section-bottom-60 section-sm-100">
                                <div class="range">
                                    <div class="cell-xs-12">
                                        <h3>
                                            Materiales Industriales <br>de Seguridad <br>y Limpieza
                                        </h3>
                                    </div>
                                    <div class="cell-xs-12 cell-md-11 offset-top-20 offset-sm-top-51">
                                        <p>
                                            Ofreciendo las mejores marcas en el mercado industrial, servicio
                                            personalizado, programas de stock programado, soporte técnico 24/7 en su
                                            empresa o en nuestras líneas telefónicas.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cell-xs-12 cell-sm-4">
                            <div class="relative">
                                <div class="image-wrap image-wrap-variant-3">
                                    <img src="assets/images/home-3.png" alt="" width="647" height="686"
                                        style="height: 560px;">
                                </div>
                                <div class="decoration-block decoration-block-left bg-cape-honey"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>

    </main>
    <!-- Products-->
    <section id="main"
        class="section-80 section-sm-130 section-lg-top-181 section-lg-bottom-189 section-decorated bg-image bg-image-3"
        style="background-image: url('assets/images/bg-image-3.jpg')">
        <div class="decoration-element decoration-element-top"></div>
        <div class="decoration-element decoration-element-bottom"></div>
        <div class="shell">
            <div class="range text-center">
                <div class="cell-xs-12">
                    <div class="range range-sm-center">
                        <div class="cell-xs-12">
                            <h3>Máxima calidad &amp; valor<br>Productos de seguridad e higiene</h3>
                        </div>
                        <div class="cell-xs-12 cell-md-10 offset-top-61">
                            <p>
                                Ofrecemos una amplia gama de productos y equipos de seguridad e higiene seleccionados a
                                mano que han sido probados por clientes y empleados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="range offset-top-51 offset-md-top-105 text-center text-sm-left">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none; text-align: center;">
                        <li>
                            <img src="assets/images/products/harness.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        </li>
                        <li>Arnes de seguridad</li>

                        <li><img src="assets/images/products/denim_shirt.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        </li>
                        <li>Camisa de mezclilla <br>seguridad</li>

                        <li><img src="assets/images/products/denim_hat.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Capucha de mezclilla <br>industrial</li>

                        <li>
                            <img src="assets/images/products/mask.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Careta de protector <br>facial</li>

                        <li>
                            <img src="assets/images/products/helmet.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Casco de seguridad</li>

                        <li>
                            <img src="assets/images/products/industrial_vest.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Chaleco industrial</li>

                        <li>
                            <img src="assets/images/products/shoes_segurity.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Zapatas de seguridad</li>

                        <li>
                            <img src="assets/images/products/industrial_apron_bib.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Mandil-pechera industrial</li>

                        <li>
                            <img src="assets/images/products/kevlar_forearm_sleeve.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Manga kevlar-antebrazo</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none; text-align: center;">
                        <li>
                            <img src="assets/images/products/security_tape.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Cinta seguridad industrial</li>

                        <li>
                            <img src="assets/images/products/security_cone.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Cono seguridad industrial</li>

                        <li>
                            <img src="assets/images/products/industrial_face_shield.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Cubrebocas industrial</li>

                        <li>
                            <img src="assets/images/products/industrial_girdles.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Fajas industrial</li>

                        <li>
                            <img src="assets/images/products/gloves_nitrilo.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Guantes nitrilo <br> Safe Fit</li>

                        <li>
                            <img src="assets/images/products/kit_brigadista_industrial.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Kit brigadista industrial</li>

                        <li>
                            <img src="assets/images/products/kit_seguridad_ soldador.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Kit seguridad para<br> soldador</li>

                        <li>
                            <img src="assets/images/products/glasses_segurity.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Lentes de seguridad</li>

                        <li>
                            <img src="assets/images/products/supplies_cleaning_kits.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Insumos Kits de <br />Limpieza</li>

                        <li>
                            <img src="assets/images/products/wd_40.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>WD-40</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none; text-align: center;">


                        <li>
                            <img src="assets/images/products/industrial_filters_filter_material.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Filtros Industriales y<br> Material Filtrante</li>

                        <li>
                            <img src="assets/images/products/hardware_material.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Material de ferreteria</li>

                        <li>
                            <img src="assets/images/products/industrial_wristband.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Muñequera industrial</li>

                        <li><img src="assets/images/products/plugs_3m.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        </li>
                        <li>Caja de tapones<br>auditivos desechables<br>3M</li>

                        <li><img src="assets/images/products/plugs_quantium.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        </li>
                        <li>Caja de tapones <br>auditivos rehusable Quantum</li>

                        <li>
                            <img src="assets/images/products/reusable_clute_box_plugs.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Tapones Rehusables <br>Clute Caja</li>

                        <li>
                            <img src="assets/images/products/meteor_disposable_earplugs.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Tapones Desechables Meteor</li>

                        <li>
                            <img src="assets/images/products/kleenguard_earplug_box.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Tapon Auditivo <br> KleenGuard Caja</li>
                    </ul>
                </div>
            </div>

            <p class="title-products" style="margin-top: 15%;">
                Tambien tenemos productos de las marcas.
            </p>
            <div class="range offset-top-51 offset-md-top-105 text-center text-sm-left" class="web">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none; text-align: center;">
                        <li>
                            <img src="assets/images/products/dogo_tuls.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Dogo tuls</li>
                    </ul>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none; text-align: center;">
                        <li>
                            <img src="assets/images/products/golden_eagle.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Golden eagle</li>
                    </ul>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none; text-align: center;">
                        <li>
                            <img src="assets/images/products/jyrsa.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Jyrsa</li>
                    </ul>
                </div>
            </div>

            <div class="range offset-top-51 offset-md-top-105 text-center text-sm-left" class="mobile">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <ul class="marked-list" style="list-style-type: none;">
                        <li>
                            <img src="assets/images/products/dogo_tuls.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Dogo tuls</li>
                    </ul>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <ul class="marked-list">
                        <li>
                            <img src="assets/images/products/golden_eagle.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Golden eagle</li>
                    </ul>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <ul class="marked-list">
                        <li>
                            <img src="assets/images/products/jyrsa.png" alt="harness"
                                style="border-radius: 50%;width: 80px;height: 80px;border-style: solid;border-color: #fe8418;border-width: 1px;">
                        <li>
                        <li>Jyrsa</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <!-- Contacts-->
    <section class="section-80 section-sm-100 section-lg-top-163 section-lg-bottom-130">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h3>Siempre estamos listos <br>para ayudarte</h3>
                    <p class="offset-sm-top-61">
                        Hay muchas formas de contactarnos. Puede escribirnos en nuestras redes sociales, darnos un
                        llama o envía un email, elige lo que más te convenga.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <!-- RD Mailform result field-->
                    <div class="rd-mailform-validate"></div>
                    <!-- RD Mailform-->
                    <form data-result-class="rd-mailform-validate" data-form-type="contact" method="post"
                        action="bat/rd-mailform.php" class="rd-mailform">
                        <div class="range">
                            <div class="cell-md-4">
                                <div class="mfInput" data-children-count="1">
                                    <input type="text" name="name" [(ngModel)]="name" data-constraints="@NotEmpty"
                                        placeholder="Tu nombre">
                                    <div class="mfValidation"></div>
                                </div>
                            </div>
                            <div class="cell-md-4">
                                <div class="mfInput" data-children-count="1">
                                    <input type="text" name="email" [(ngModel)]="email"
                                        data-constraints="@NotEmpty @Email" placeholder="Tu correo electronico">
                                    <div class="mfValidation"></div>
                                </div>
                            </div>
                            <!--<div class="cell-md-4">
                                <div class="mfInput" data-children-count="1">
                                    <input type="text" name="zip" [ngModel]=">" placeholder="Cuidad o CP">
                                    <div class="mfValidation"></div>
                                </div>
                            </div>-->
                            <br>
                            <div class="cell-xs-12 offset-top-30">
                                <div class="mfInput" data-children-count="1">
                                    <textarea name="message" [(ngModel)]="message" data-constraints="@NotEmpty"
                                        placeholder="Mensaje"></textarea>
                                    <div class="mfValidation"></div>
                                </div>
                            </div>
                        </div>
                        <div class="offset-top-30">
                            <!--<button class="btn btn-default">Enviar</button>-->
                            <a class="btn btn-default" (click)="goEmail()">Enviar</a>
                        </div>
                    </form>
                    <div class="alert success" *ngIf="success">
                        Su información se ha enviado con exito, en breve uno de nuestros compañeros estara en contacto contigo, Saludos.
                    </div>
                    <div class="alert danger" *ngIf="error">
                        Se ha presentado un problema favor de comunicarte al telefono 812 362 7112 estamos para escucharte, Saludos.
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- RD Google Map-->
    <div class="rd-google-map">
        <div id="google-map" data-zoom="14" data-x="-73.9874068" data-y="40.643180"
            class="rd-google-map__model lazy-loaded" style="position: relative; overflow: hidden;">
            <a href="https://www.google.com/maps/place/Lim%C3%B3n+214,+Valle+de+Escobedo,+66053+Cd+Gral+Escobedo,+N.L./@25.799807,-100.304019,17z/data=!3m1!4b1!4m5!3m4!1s0x86629309764b251d:0xb97acd4e99547bdf!8m2!3d25.799807!4d-100.3018303"
                target="_blank"><img src="assets/images/direction.png" class="img-responsive"
                    style="width: 100%; height: 100%;"></a>
        </div>
        <ul class="rd-google-map__locations">
            <li data-x="-73.9874068" data-y="40.643180">
                <p>Priv. Limón #214 Col.Valles de Escobedo, Escobedo, Nuevo León<span>812 362 7112</span></p>
            </li>
        </ul>
    </div>

    <!-- Page Footer-->
    <footer class="page-foot text-center">
        <div class="shell">
            <div class="range range-sm-middle">
                <div class="cell-xs-12 cell-md-3 text-md-left">
                    <ul class="list-inline">
                        <li><i class="fab fa-facebook-square icon-md icon-white" style="color: white;"></i></li>
                        <li><i class="fab fa-twitter-square icon-md icon-white fl-glypho-twitter"></i></li>
                        <li><i href="#" class="fab fa-instagram-square icon-md icon-white fl-glypho-instagram19"></i>
                        </li>
                    </ul>
                </div>
                <div class="cell-xs-12 cell-md-preffix-1 cell-md-3 offset-top-30 offset-md-top-0">
                    <address class="contact-info"> <i class="fa fa-map-marker"></i> Priv. Limón #214 Col.Valles de
                        Escobedo<br>Escobedo, NL.<br>66053</address>
                    <address class="contact-info"> <i class="fa fa-envelope"></i> ventas@suministrossindsa.com</address>
                    <address class="contact-info"> <i class="fa fa-phone"></i> 812 362 7112</address>
                </div>
                <div class="cell-xs-12 cell-md-preffix-1 cell-md-4 text-md-right offset-top-30 offset-md-top-0">
                    <p class="rights">©<span id="copyright-year">2021</span> | All rights reserved | This template is
                        made with by <a target="_blank" href="http://coffeholik.com/"><b>Coffeholik</b></a>
                        <!-- {%FOOTER_LINK}-->
                    </p>
                </div>
            </div>
        </div>
    </footer>
</div>