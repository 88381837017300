import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }

  async sendEmail(data){
    //const response = await this.httpClient.post(`http://192.168.64.2/sindsa/email/index.php`, data, { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }).toPromise();
    //const response = await this.httpClient.post(`http://coffeholik.com/sindsa/email/index.php`, data, { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }).toPromise();
    const response = await this.httpClient.post(`https://suministrossindsa.com/email/index.php`, data, { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }).toPromise();
    return response;
  }
}
